import {Navigation} from "@shopify/polaris";
import React from "react";
import {History, Redirect} from "@shopify/app-bridge/actions";
import {useLocation} from "react-router-dom";

export const NAV_STATE = {
    REWARD_ADMIN: 'reward_admin',
    ANALYTICS: 'analytics',
    MARKETING: 'marketing',
    CONTACT: 'contact',
    SETTINGS: 'settings'
};

const NavigationMarkup = ({ historyFunction, redirectFunction, updateNavigation }) => {
    const location = useLocation();
    return (
        <Navigation location={location.pathname}>
            <Navigation.Section
                items={[
                    {
                        label: 'Back to Shopify',
                        onClick: () => {console.log(redirectFunction);redirectFunction.dispatch(Redirect.Action.ADMIN_SECTION, {
                            path: '/apps',
                            newContext: true
                        })}
                    },
                ]}
            />
            <Navigation.Section
                separator
                title="Loyalty program"
                items={[
                    {
                        exactMatch: true,
                        url: "/",
                        label: 'Loyalty Admin',
                        // icon: null,
                        // onClick: () => historyFunction.dispatch(History.Action.PUSH, '/loyalty_admin')
                    },
                    {
                        url: "/analytics",
                        disabled: true,
                        label: 'Analytics',
                        // icon: null/*OrdersMajor*/,
                        onClick: () => {},
                    },
                    {
                        url: '/marketing',
                        disabled: true,
                        label: 'Marketing',
                        // icon: null/*OrdersMajor*/,
                        onClick: () => {},
                    },
                ]}
                action={{
                    label: 'Marketing',
                    // icon: null,
                    onClick: () => {},
                }}
            />
            <Navigation.Section
                separator
                fill
                items={[
                    {
                        url:"/contact",
                        disabled: true,
                        label: 'Contact Us',
                        // icon: null/*HomeMajor*/,
                        onClick: () => {},
                    },
                    {
                        url: "/settings",
                        disabled: false,
                        label: 'Settings',
                        // icon: null/*OrdersMajor*/,
                        // onClick: () => historyFunction.dispatch(History.Action.PUSH, '/settings')
                    },
                ]}
                action={{
                    // icon: null/*ConversationMinor*/,
                    accessibilityLabel: 'Contact support',
                    onClick: () => {},
                }}
            />
        </Navigation>
    )
};

export { NavigationMarkup };
