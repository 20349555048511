import React from 'react';
import {API_PREFIX} from "./constants";

class AppStateProvider extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            settings: {},
            rewardCustomers: [],
            general_integration: {},
            sitewide_discounts: [],
            reward_customer_url: null,
            reward_discounts: [],
            navState: null,
            modalActive: false,
        };
    }

    componentDidMount() {
        const { fetchFunction } = this.props;

        // Fetch User Discount Rewards
        fetchFunction(`${API_PREFIX}/client/`)
            .then(response => {
                if (!response.ok) {
                    throw new Error("failed to fetch reward discounts...");
                }
                return response.json();
            })
            .then(data => {
                this.setState({ reward_customer_url: data.reward_customer_url || '' })
            })
            .catch(err => {
                console.log(err)
            })
        fetchFunction(`${API_PREFIX}/reward_discount/`)
            .then(response => {
                if (!response.ok) {
                    throw new Error("failed to fetch reward discounts...");
                }
                return response.json();
            })
            .then(data => {
                this.setState({ reward_discounts: data.reward_discounts || [] })
            })
            .catch(err => {
                console.log(err)
            })
        // Fetch Business Settings
        fetchFunction(`${API_PREFIX}/settings/`)
            .then(response => response.json())
            .then(({ settings }) => {
                this.setState({ settings })
            })
            .catch(err => {
                console.log(err)
            })
        // Fetch Reward Users
        fetchFunction(`${API_PREFIX}/customer/`)
            .then(response => response.json())
            .then(data => {
                this.setState({ rewardCustomers: data.customers })
            })
            .catch(err => {
                console.log(err)
            })
        // Fetch reward integration settings
        fetchFunction(`${API_PREFIX}/integration/`)
            .then(response => {
                if (!response.ok) {
                   throw new Error("failed to fetch integrations")
                }
                return response.json();
            })
            .then(data => {
                this.setState({ general_integration: data.general_integration, product_integrations: data.product_integrations || [] });
            })
            .catch(err => {
                console.log(err)
            })
    }

    refreshDiscounts() {
        const { fetchFunction } = this.props;

        // Fetch User Discount Rewards
        fetchFunction(`${API_PREFIX}/reward_discount/`)
            .then(response => response.json())
            .then(data => {
                this.setState({ reward_discounts: data.reward_discounts })
            })
            .catch(err => {
                console.log(err)
            })
    }

    deleteDiscounts(ids) {
        const { fetchFunction } = this.props;

        fetchFunction(`${API_PREFIX}/reward_discount/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ discount_ids: ids })
        })
            .then(res => {
                if (res.ok) {
                    this.setState(prevState => ({ reward_discounts: prevState.reward_discounts.filter(d => !ids.includes(d.id)) }));
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    updateSettings(payload) {
        const { fetchFunction } = this.props;
        fetchFunction(`${API_PREFIX}/settings/`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ patch: payload })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error("Failed to update");
                }
                return response.json()
            })
            .then(() => {
                this.setState(prevState => ({ settings: { ...prevState.settings, payload }  }))
            })
            .catch(err => {
                console.log(err)
            })
    }

    async createRewardDiscount(payload) {
        const { fetchFunction } = this.props;

        return new Promise((resolve, reject) => {
            fetchFunction(`${API_PREFIX}/reward_discount/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ discount_type: payload })
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error("Failed to create");
                    }
                    return response.json()
                })
                .then(data => {
                    this.setState(prevState => ({ reward_discounts: [...prevState.reward_discounts, data.reward_discount ] }));
                    resolve();
                })
                .catch(err => {
                    console.log(err)
                    reject();
                })
        });
    }

    updateRemoteIntegration(type, method, payload) {
        const { fetchFunction } = this.props;
        return new Promise((resolve, reject) => {
            fetchFunction(`${API_PREFIX}/integration/?type=${type}`, {
                method: method,
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error("bad integration")
                    }
                    if (type === 'general') {
                        this.setState(prevState => ({ general_integration: { ...prevState.general_integration, ...payload } }))
                    }
                })
                .then(data => {
                    resolve()
                })
                .catch(err => {
                    reject(err)
                })
        });
    }

    updateNavigation({ navState }) {
        this.setState({ navState })
    }

    openModal() {
        this.setState({modalActive: true })
    }

    closeModal() {
        this.setState({modalActive: false })
    }

    installClientApp() {
        const { fetchFunction } = this.props;
        fetchFunction(`${API_PREFIX}/client/`, { method: 'POST' })
            .then(response => {
                if (!response.ok) {
                    throw new Error("Unable to install client application.")
                }
            })
            .catch(err => {
                throw err;
            });
    }

    render() {
        return this.props.children({
            refreshDiscounts: this.refreshDiscounts.bind(this),
            updateRemoteIntegration: this.updateRemoteIntegration.bind(this),
            ...this.state,
            installClientApp: this.installClientApp.bind(this),
            updateNavigation: this.updateNavigation.bind(this),
            updateSettings: this.updateSettings.bind(this),
            closeModal: this.closeModal.bind(this),
            openModal: this.openModal.bind(this),
            createRewardDiscount: this.createRewardDiscount.bind(this),
            deleteDiscounts: this.deleteDiscounts.bind(this)
        });
    }
}

export default AppStateProvider;
