import React, {useRef, useState} from "react";
import {
    Page,
    Button,
    Tabs,
    IndexTable,
    TextStyle,
    Card,
    EmptyState,
    List,
    TextContainer,
    Banner,
} from "@shopify/polaris";
import { DiscountCreatorModal } from "./components";
import LoyaltyPointIntegrations from './LoyaltyAdminIntegrations';

const promotedBulkCustomerActions = [
    {
        content: 'Edit Reward Customers',
        onAction: () => console.log('Todo: implement bulk add tags'),
    },
];
const promotedBulkRewardActions = [
    {
        content: 'Edit Rewards',
        onAction: () => console.log('Todo: implement bulk add tags'),
    },
];
const bulkCustomerActions = [
    {
        content: 'Ban User(s)',
        onAction: () => console.log('Todo: implement make-active'),
    },
    {
        content: 'Remove Users',
        onAction: () => console.log('Todo: implement make-unactive'),
    },
];
const loyaltyAdminTabs = [
    {
        id: 'loyalty_overview',
        content: 'Loyalty Overview',
        accessibilityLabel: 'loyalty overview',
        panelID: 'all-customers-content-1',
    },
    {
        id: 'discount_create',
        content: 'Loyalty Discount Rewards',
        accessibilityLabel: 'Create discount',
        panelID: 'all-customers-content-1',
    },
    {
        id: 'loyalty_points',
        content: 'Loyalty Points',
        panelID: 'accepts-marketing-content-1',
    },
    {
        id: 'loyalty_customers',
        content: 'Loyalty Customers',
        panelID: 'repeat-customers-content-1',
    },
    {
        id: 'loyalty_customer_ui',
        content: 'Loyalty Customer UI',
        panelID: 'loyalty-customer-content-1',
    },
];


const LoyaltyAdmin = ({ reward_discounts = [], reward_customer_url, rewardCustomers: customers, deleteDiscounts, settings, installClientApp, addToast, ...props }) => {
    const [ selectedTab, setSelectedTab ] = useState(0);
    const [ selectedDiscounts, setSelectedDiscounts ] = useState([]);
    const [ selectedCustomers, setSelectedCustomers ] = useState([]);
    const [ viewState, setViewState ] = useState('view_discount');
    const [ modalActive, setModalActive ] = useState(false);
    const frameRef = useRef();

    // frameRef.contentWindow.postMessage({ call:'sendValue', value: 'nice' });

    const handleSelectionChange = (selectionType, toggleType, selection) => {
        if (toggleType)
            setSelectedDiscounts(prevState => [...prevState, selection])
        else
            setSelectedDiscounts(prevState => [...prevState.filter(s => s !== selection)])
    };

    const handleCustomerSelectionChange = (selectionType, toggleType, selection) => {
        if (toggleType)
            setSelectedCustomers(prevState => [...prevState, selection])
        else
            setSelectedCustomers(prevState => [...prevState.filter(s => s !== selection)])
    };

    const handleInstallClientApp = () => {
        try {
            installClientApp();
        }
        catch (e) {
            addToast({
                message: "Failed to install client app",
                error: true
            })
        }
    };

    const emptyMarkup = (
        <Card sectioned>
            <EmptyState
                heading="Setup Rewards For Customers!"
                action={{content: 'Add Reward', onAction: () => setViewState('create_discount')
                    }}
                secondaryAction={{content: 'Learn more', url: 'https://help.shopify.com'}}
                image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
            >
                <p>Track and modify your custom rewards for loyalty customers.</p>
            </EmptyState>
        </Card>
    );

    const emptyCustomerMarkup = (
        <Card sectioned>
            <EmptyState
                heading="Add customers to Loyalty Program!"
                action={{content: 'Add Customer'}}
                secondaryAction={{content: 'Learn more', url: 'https://help.shopify.com'}}
                image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
            >
                <p>Track and modify your customers for your loyalty program.</p>
            </EmptyState>
        </Card>
    );

    const rewardStates = ['success','subdued'];
    const rowMarkup = reward_discounts.map(
        ({ id, title, required_points, application_type, points, active }, index) => (
            <IndexTable.Row
                id={id}
                key={id}
                selected={selectedDiscounts.includes(id)}
                position={index}
                status={active ? rewardStates[0] : rewardStates[1]}
            >
                <IndexTable.Cell>
                    <TextStyle variation="strong">{title}</TextStyle>
                </IndexTable.Cell>
                <IndexTable.Cell>{required_points}</IndexTable.Cell>
                <IndexTable.Cell>{application_type}</IndexTable.Cell>
                <IndexTable.Cell>{points}</IndexTable.Cell>
            </IndexTable.Row>
        ),
    );

    const rowCustomerMarkup = customers?.map(
        ({ id, username, first_name, last_name, email, points }, index) => (
            <IndexTable.Row
                id={id}
                key={id}
                selected={selectedCustomers.includes(id)}
                position={index}
            >
                <IndexTable.Cell>{username}</IndexTable.Cell>
                <IndexTable.Cell>{`${first_name} ${last_name}`}</IndexTable.Cell>
                <IndexTable.Cell>{email}</IndexTable.Cell>
                <IndexTable.Cell>{points}</IndexTable.Cell>
            </IndexTable.Row>
        ),
    );

    const bulkRewardActions = [
        {
            content: 'Set Rewards Active',
            onAction: () => console.log('Todo: implement make-active'),
        },
        {
            content: 'Set Rewards In-Active',
            onAction: () => console.log('Todo: implement make-active'),
        },
        {
            content: 'Delete Rewards',
            onAction: () => deleteDiscounts(selectedDiscounts),
        },
    ];

    return (
            <Tabs
                tabs={loyaltyAdminTabs}
                selected={selectedTab}
                onSelect={setSelectedTab}
            >
                {selectedTab === 0 && (
                    <Page
                        title="Loyalty Overview"
                        fullWidth
                    >
                        <Card title="Client Installed" sectioned>
                            <TextContainer>
                                <Banner
                                    title={`Rewards program is ${settings.client_enabled ? '' : 'not'} installed for the client`}
                                    status={settings.client_enabled ? "success" : "warning"}
                                    {...!settings.client_enabled && { action: {content: 'Install Client Application', onAction: handleInstallClientApp}}}
                                    secondaryAction={{content: 'Contact us for custom integrations', url: ''}}
                                >
                                    {!settings.client_enabled ? (
                                        <p>Install client so your customers can use redeem and use rewards at checkout.</p>
                                    ) : (
                                        <p>Client has been installed for your customers to use.</p>
                                    )}
                                </Banner>
                            </TextContainer>
                        </Card>
                        <Card title="Loyalty Program Insights" sectioned>
                            <List>
                                <List.Item>
                                    {customers?.length || 0} Loyalty Customers
                                </List.Item>
                                <List.Item>
                                    22 Rewards Redeemed
                                </List.Item>
                                <List.Item>
                                    13 Rewards Used
                                </List.Item>
                                <List.Item>
                                    5472 Points Obtained
                                </List.Item>
                            </List>
                        </Card>
                        <Card title="Customer UI Settings" sectioned>

                        </Card>
                    </Page>
                )}
                {selectedTab === 1 && (
                    <Page
                        title="Loyalty Rewards"
                        fullWidth
                        primaryAction={
                            <Button
                                primary
                                onClick={() => setViewState('create_discount')}
                                connectedDisclosure={{
                                    accessibilityLabel: 'view reward discounts',
                                    actions: [{content: 'View Reward Discounts', onAction: () => setViewState('view_discount') }],
                                }}
                            >
                                Create Reward Discount
                            </Button>
                        }
                    >
                        <>
                            {viewState === 'create_discount' ?
                                <DiscountCreatorModal {...props} modalActive={modalActive} closeModal={() => setModalActive(false)}/>
                                :
                                <>
                                    {!!reward_discounts?.length && (
                                        <Card>
                                        <IndexTable
                                            resourceName={{plural: "Discounts", singular: "Discount"}}
                                            itemCount={reward_discounts?.length}
                                            selectedItemsCount={
                                                reward_discounts?.length === selectedDiscounts.length ? 'All' : selectedDiscounts.length
                                            }
                                            onSelectionChange={handleSelectionChange}
                                            hasMoreItems
                                            bulkActions={bulkRewardActions}
                                            promotedBulkActions={promotedBulkRewardActions}
                                            headings={[
                                                {title: 'Title'},
                                                {title: 'Required Points'},
                                                {title: 'Order count'},
                                                {title: 'Amount spent'},
                                            ]}
                                        >
                                            {rowMarkup}
                                        </IndexTable>
                                        </Card>
                                    )}
                                    {!reward_discounts?.length && emptyMarkup}
                                </>
                            }
                        </>
                    </Page>
                )}
                {selectedTab === 2 && (
                    <LoyaltyPointIntegrations {...props} />
                )}
                {selectedTab === 3 && (
                    <Page
                        title="Loyalty Customers"
                        fullWidth
                        primaryAction={
                            <Button
                                primary
                                onClick={() => setModalActive(true)}
                                connectedDisclosure={{
                                    accessibilityLabel: 'Other save actions',
                                    actions: [{content: 'Save as new'}],
                                }}
                            >

                            </Button>
                        }
                    >
                        {!!customers?.length && (
                            <Card>
                                <IndexTable
                                    resourceName={{plural: "Customers", singular: "Customer"}}
                                    itemCount={customers?.length}
                                    selectedItemsCount={
                                        customers?.length === selectedCustomers.length ? 'All' : selectedCustomers.length
                                    }
                                    onSelectionChange={handleCustomerSelectionChange}
                                    hasMoreItems
                                    bulkActions={bulkCustomerActions}
                                    promotedBulkActions={promotedBulkCustomerActions}
                                    headings={[
                                        {title: 'Username'},
                                        {title: 'Full Name'},
                                        {title: 'Email'},
                                        {title: 'Points'},
                                    ]}
                                >
                                    {rowCustomerMarkup}
                                </IndexTable>
                            </Card>
                        )}
                        {!customers?.length && emptyCustomerMarkup}
                    </Page>
                )}
                {selectedTab === 4 && (
                    <iframe
                        ref={frameRef}
                        title="Loyalty Customer UI"
                        src={`${reward_customer_url}?test_view=true`}
                        style={{width: '100%', height: '100%'}}
                    />
                )}
            </Tabs>
    );
}

export default LoyaltyAdmin;
