import React, {useCallback, useState} from "react";
import { Button, Page, Card, SettingToggle, TextStyle } from "@shopify/polaris";

function RewardSettingToggle({ active: isActive, text, onToggle }) {
    const [active, setActive] = useState(isActive);

    const handleToggle = useCallback(() => {
        setActive(isActive => !isActive);
        onToggle();
    }, []);

    const contentStatus = active ? 'Deactivate' : 'Activate';
    const textStatus = active ? 'enabled' : 'disabled';

    return (
        <SettingToggle
            action={{
                content: contentStatus,
                onAction: handleToggle,
            }}
            enabled={active}
        >
            {text} <TextStyle variation="strong">{textStatus}</TextStyle>.
        </SettingToggle>
    );
}

const Settings = ({ settings: prevSettings, updateSettings }) => {
    const [ settings, setSettings ] = useState(prevSettings);
    const isDisabled = () => {
        const keys = Object.keys(prevSettings);
        for (let i in keys) {
            console.log(prevSettings[keys[i]], settings[keys[i]])
            if (prevSettings[keys[i]] !== settings[keys[i]]) {
                return false;
            }
        }
        return true;
    };
    return (
        <Page>
            <Card title={"Business Settings"} sectioned>
                <p>
                    This section is where you can adjust and update settings for the rewards that apply to your
                    customers.
                    The settings can be adjusted.
                </p>
            </Card>
            <RewardSettingToggle
                onToggle={() => setSettings(s => ({ ...s, allow_multiple_discounts: !s.allow_multiple_discounts }))}
                text="Multiple discounts are"
                active={settings.allow_multiple_discounts}
            />
            <RewardSettingToggle
                onToggle={() => setSettings(s => ({ ...s, client_enabled: !s.client_enabled }))}
                text="Client UI is"
                active={settings.client_enabled}/>
            <RewardSettingToggle
                onToggle={() => setSettings(s => ({ ...s, points_expire: !s.points_expire}))}
                text="Point expiry is" active={settings.points_expire}/>
            <RewardSettingToggle
                onToggle={() => setSettings(s => ({ ...s, program_enabled: !s.program_enabled}))}
                text="Loyalty program is" active={settings.program_enabled}/>
            <RewardSettingToggle
                onToggle={() => setSettings(s => ({ ...s, refund_reward: !s.refund_reward}))}
                text="Refundable rewards are" active={settings.refund_reward}/>
            <div style={{ display: 'flex', justifyContent: 'end', marginTop: '1.6rem' }}>
                <Button disabled={isDisabled()}>Save</Button>
            </div>
        </Page>
    );
}

export default Settings;
