import React from 'react';
import { FormLayout, TextField, Tag, Button, Select } from "@shopify/polaris";

const ProductIntegrationRule = ({ product_integration, updateProductIntegration, product, openResourcePicker }) => (
    <FormLayout.Group>
        <TextField
            label={'value'}
            labelHidden
            type={'number'}
            onChange={value => updateProductIntegration({ ...product_integration, value })}
            value={product_integration.value}
        />
        <Select
            options={[{label: 'times points per', value: 'points_per_dollar'}, {label: 'points per', value: 'static_points'}]}
            onChange={type => updateProductIntegration({ ...product_integration, type })}
            label={'type'}
            labelHidden
        />
        {!product && <Button onClick={() => openResourcePicker({ onSelection: selection => {}, type: 'Product' })}>Specify Product</Button>}
        {!!product && (<Tag>{product.title}</Tag>)}
    </FormLayout.Group>
);

export default ProductIntegrationRule;
