import React from "react";
import { FormLayout, Select, TextField, Button, Tag, Stack  } from "@shopify/polaris";

const defaultTypeState = {
    pay: { value: null, payType: 'shipping' },
    amount: { value: null, amountType: 'Product' },
    date: { starts_at: null, ends_at: null },
    customer: { prerequisite_customers: [] },
};

const Constraint = ({ data, onUpdate, openResourcePicker, openCustomerPicker }) => (
    <FormLayout.Group condensed>
        <Select
            labelHidden
            label="Collection rule type"
            options={[{label:'Must pay',value:'pay'},{label:'Must purchase',value:'amount'},{label:'Valid from/to',value:'date'},{label:'Valid for customer(s)',value:'customer'}]}
            value={data.type}
            onChange={value => onUpdate({ type: value, ...defaultTypeState[value] })}
        />
        {data.type === 'date' &&
            <TextField
                label={'starts_at'}
                onChange={date => onUpdate({ ...data, starts_at: date })}
                value={data.starts_at}
                labelHidden
                type="date"
            />
        }
        {data.type === 'date' &&
            <TextField
                label={'ends_at'}
                onChange={date => onUpdate({ ...data, ends_at: date })}
                value={data.ends_at}
                labelHidden type="date"
            />
        }
        {(data.type === 'pay' || data.type === 'amount') &&
            <TextField
                labelHidden
                label={'value'}
                type={'number'}
                prefix={data.type === 'pay' ? '$' : '#'}
                placeholder={data.type === 'pay' ? "69.69" : "100"}
                value={data.value}
                onChange={value => onUpdate({ ...data, value })}
            />
        }
        {data.type === 'pay' &&
        <Select
                labelHidden
                label="Collection rule type"
                options={[{label:'for shipping',value:'shipping'},{label:'for subtotal',value:'subtotal'}]}
                value={data.payType}
                onChange={payType => onUpdate({ ...data, payType })}
            />
        }
        {data.type === 'amount' &&
        <Select
                labelHidden
                label="Collection rule type"
                options={[{label:'of product(s)',value:'Product'},{label:'from collection(s)',value:'Collection'}]}
                value={data.amountType}
                onChange={amountType => onUpdate({ ...data, amountType })}
            />
        }
        <Stack spacing="tight" alignment="center">
            {data.type === 'amount' &&
            <Button onClick={() => openResourcePicker({ onSelection: selections => onUpdate({ ...data, prerequisite_products: selections.selection }), type: data.amountType  })}>{`Add ${data.amountType}(s)`}</Button>
            }
            {data?.prerequisite_products?.length && (<Tag>{data?.prerequisite_products[0].title}</Tag>)}
            {data?.prerequisite_products?.length > 1 && (<Tag>+{data.prerequisite_products.length-1}</Tag>)}
        </Stack>
        {data.type === 'customer' && (
            <>
                <Button onClick={() => openCustomerPicker({ onSelection: selections => onUpdate({ ...data, prerequisite_customers: selections }) })}>Add Customer(s)</Button>
                {data?.prerequisite_customers?.map((customer, index) => (<Tag>{customer.first_name}</Tag>))}
            </>
        )}
    </FormLayout.Group>
);

export default Constraint;
