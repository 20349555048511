import {Button, Card, FooterHelp, Link, Page, Stack, TextContainer, TextField, ContextualSaveBar, Form, ButtonGroup} from "@shopify/polaris";
import React, { useState, useEffect } from "react";
import ProductIntegrationRule from "./components/ProductIntegrationRule";
import {ResourcePicker} from "@shopify/app-bridge-react";

const LoyaltyPointIntegrations = ({ general_integration, product_integrations = [], updateRemoteIntegration }) => {
    const [ integration, updateIntegration ] = useState({...general_integration});
    const [ productIntegrations, updateProductIntegrations ] = useState([...product_integrations]);
    const [ pickerOpen, updatePickerOpen ] = useState(false);

    useEffect(() => {
        updateIntegration({ ...general_integration });
        console.log(integration.points_per_dollar)
    }, [general_integration]);

    const addProductIntegration = () => {
        updateProductIntegrations([...productIntegrations, {}])
    };
    const handleOpenResourcePicker = ({ }) => {
        updatePickerOpen(true);
    }
    const handleUpdateIntegrations = () => {
        const generalKeys =  Object.keys(general_integration)
        const generalPatch = {};
        for (const key of generalKeys) {
            if (general_integration[key] !== integration[key]) {
                generalPatch[key] = integration[key];
            }
        }
        if (Object.keys(generalPatch).length !== 0) {
            updateRemoteIntegration('general', 'PATCH', generalPatch)
        }

        // Update Product Integrations
        if (product_integrations.length !== 0) {
            const productPatch = product_integrations.map(() => ({}));
            let modified = false;
            for (let i in product_integrations) {
                const productKeys = Object.keys(productIntegrations[i])
                for (const key of productKeys) {
                    if (product_integrations[i][key] !== productIntegrations[i][key]) {
                        productPatch[i][key] = productIntegrations[i][key];
                        modified = true;
                    }
                }
            }
            if (modified) {
                updateRemoteIntegration('product', 'PATCH', { product_integrations: productPatch })
            }
        }
        // Add new product integrations
        if (product_integrations.length !== productIntegrations.length) {
            for (let i = product_integrations.length; i < productIntegrations.length; i++) {
                updateRemoteIntegration('product', 'POST', productIntegrations[i])
            }
        }
    }

    return (
        <Page
            title="Loyalty Point Acquisition"
            fullWidth
            secondaryActions={[
                {content: 'Help'}
            ]}
    >
            <Form onSubmit={handleUpdateIntegrations}>
            <Card sectioned title="Ways that Customers Earn Rewards Points">
                <TextContainer>
                    <p>These controls will affect how your customers are able to obtain reward points they can exchange for the rewards you have created.</p>
                </TextContainer>
                <Card.Section title="General">
                    <Card.Subsection>
                        <Stack vertical={false} alignment={'center'}>
                            <Stack.Item>
                                <TextField
                                    onChange={points_per_dollar => updateIntegration({ ...integration, points_per_dollar })}
                                    disabled={!integration.points_per_dollar_enabled}
                                    value={`${integration.points_per_dollar}`}
                                    labelHidden
                                    type={'number'}
                                    label={"points"}
                                />
                            </Stack.Item>
                            <Stack.Item>
                                <p>points per '$' spent.</p>
                            </Stack.Item>
                            <Stack.Item fill/>
                            <Stack.Item>
                                <Button onClick={() => updateIntegration({ ...integration, points_per_dollar_enabled: !integration.points_per_dollar_enabled })} primary={!integration.points_per_dollar_enabled}>{integration.points_per_dollar_enabled ? 'Disable' : 'Enable'}</Button>
                            </Stack.Item>
                        </Stack>
                    </Card.Subsection>
                    <Card.Subsection>
                        <Stack vertical={false} alignment={'center'}>
                            <Stack.Item>
                                <TextField
                                    disabled={!integration.points_per_login_enabled}
                                    onChange={points_per_login => updateIntegration({ ...integration, points_per_login })}
                                    value={`${integration.points_per_login}`}
                                    labelHidden
                                    type={'number'}
                                    label={"points"}
                                />
                            </Stack.Item>
                            <Stack.Item>
                                <TextContainer>
                                    <p>points per login.</p>
                                </TextContainer>
                            </Stack.Item>
                            <Stack.Item fill/>
                            <Stack.Item>
                                <Button onClick={() => updateIntegration({ ...integration, points_per_login_enabled: !integration.points_per_login_enabled })} primary={!integration.points_per_login_enabled}>{integration.points_per_login_enabled ? 'Disable' : 'Enable'}</Button>
                            </Stack.Item>
                        </Stack>
                    </Card.Subsection>
                    <Card.Subsection>
                        <Stack vertical={false} alignment={'center'}>
                            <Stack.Item>
                                <TextField disabled={!integration.points_per_valid_email_enabled} value={`${integration.points_per_valid_email}`} onChange={points_per_valid_email => updateIntegration({ ...integration, points_per_valid_email })} labelHidden type={'number'} label={"points"} />
                            </Stack.Item>
                            <Stack.Item>
                                points for verifying email.
                            </Stack.Item>
                            <Stack.Item fill/>
                            <Stack.Item>
                                <Button onClick={() => updateIntegration({ ...integration, points_per_valid_email_enabled: !integration.points_per_valid_email_enabled})} primary={!integration.points_per_valid_email_enabled}>{integration.points_per_valid_email_enabled ? 'Disable' : 'Enable'}</Button>
                            </Stack.Item>
                        </Stack>
                    </Card.Subsection>
                    <Card.Subsection>
                        <Stack vertical={false} alignment={'center'}>
                            <Stack.Item>
                                <TextField disabled={!integration.points_per_friend_referral_enabled} onChange={points_per_friend_referral => updateIntegration({ ...integration, points_per_friend_referral })} value={`${integration.points_per_friend_referral}`} labelHidden type={'number'} label={"points"}/>
                            </Stack.Item>
                            <Stack.Item>
                                <p>points for friend referral.</p>
                            </Stack.Item>
                            <Stack.Item fill/>
                            <Stack.Item>
                                <Button onClick={() => updateIntegration({ ...integration, points_per_friend_referral_enabled: !integration.points_per_friend_referral_enabled })} primary={!integration.points_per_friend_referral_enabled}>{integration.points_per_friend_referral_enabled ? 'Disable' : 'Enable'}</Button>
                            </Stack.Item>
                        </Stack>
                    </Card.Subsection>
                </Card.Section>
                <Card.Section title={'Product Specific Points'} actions={[{content: 'Add Rule', onAction: addProductIntegration }]}>
                    {productIntegrations.map((product_integration,index) => (
                        <ProductIntegrationRule
                            product_integration={product_integration}
                            product={null}
                            openResourcePicker={handleOpenResourcePicker}
                            updateProductIntegration={updatedIntegration => updateProductIntegrations([ ...productIntegrations.slice(0,index), updatedIntegration, ...productIntegrations.slice(index+1)])}
                        />
                    ))}
                </Card.Section>
                <Card.Section title="Social Media Integrations" actions={[{content: 'Add Integration'}]}>
                    <Card.Subsection>
                        In Construction...
                    </Card.Subsection>
                </Card.Section>
                <Card.Section title="Other Integrations" subdued>
                    <Card.Subsection>
                        Leave a review
                    </Card.Subsection>
                    <Card.Subsection>
                        Subscribed to Mailing List
                    </Card.Subsection>
                    <Card.Subsection>
                        SMS Integration
                    </Card.Subsection>
                </Card.Section>
                <Stack distribution="trailing">
                    <ButtonGroup>
                        <Button primary submit>Save Changes</Button>
                    </ButtonGroup>
                </Stack>
            </Card>
                <FooterHelp>
                    Need a different integration or have an idea?{' '}
                    <Link external url="https://help.shopify.com/manual/orders/fulfill-orders">
                        Contact us!
                    </Link>
                </FooterHelp>
                <ResourcePicker
                    resourceType="Product"
                    open={pickerOpen}
                    onCancel={() => updatePickerOpen(false)}
                    allowMultiple={false}
                />
            </Form>
        </Page>
    )
};

export default LoyaltyPointIntegrations;
