import {ClientRouter, RoutePropagator} from "@shopify/app-bridge-react";
import {Route, Switch, withRouter} from "react-router-dom";
import LoyaltyAdmin from "./LoyaltyAdmin";
import AnalyticsAdmin from "./AnalyticsAdmin";
import MarketingAdmin from "./MarketingAdmin";
import Contact from "./Contact";
import Settings from "./Settings";
import React from "react";

const Routes = ({ history, location, addToast, ...props }) => (
    <>
        <ClientRouter history={history} />
        <RoutePropagator location={location} />
        <Switch>
            <Route exact path="/">
                <LoyaltyAdmin addToast={addToast}  {...props}  />
            </Route>
            <Route exact path="/analytics">
                <AnalyticsAdmin {...props} />
            </Route>
            <Route exact path="/marketing">
                <MarketingAdmin   {...props} />
            </Route>
            <Route exact path="/contact">
                <Contact   {...props} />
            </Route>
            <Route exact path="/settings">
                <Settings   {...props} />
            </Route>
        </Switch>
    </>
);
export default withRouter(Routes);
