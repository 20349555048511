import React from 'react';
import PropTypes from 'prop-types';

import {ButtonGroup, Button, Card, ChoiceList, Form, FormLayout, RadioButton, Select, Tag, TextField, TextStyle, Stack  } from '@shopify/polaris';
import {ResourcePicker} from '@shopify/app-bridge-react';
import Constraint from "./Constraint";

import '@shopify/polaris/dist/styles.css';

const RewardTypes = [
    {
        label: 'Fixed Reward',
        value: 'fixed',
    },
    {
        label: 'Percent Reward',
        value: 'percent',
    },
    {
        label: 'Free Product',
        value: 'free',
    },
    {
        label: 'Buy one Get one Free',
        value: 'buy_one_get_one',
    },
    {
        label: 'Free Shipping',
        value: 'free_shipping',
    },
];


class DiscountCreatorModal extends React.Component {
    constructor(props) {
        super(props);
        // Setup State
        this.state = {
            // Form state
            title: '',
            description: '',
            usage: 'unbounded',
            type: RewardTypes[0].value,
            value: 0,
            amount: 0,
            entitlement_type: 'all',
            pickerOpen: false,
            pickerType: '',
            product_limited_rule: 'unlimited',
            prerequisite_to_entitlement_entitlement_quantity: null,
            onPickerSelection: () => {},
            value_type: 'fixed_amount',
            discount_application: 'shipping',
            required_points: 0,
            usage_limit: null,
            loading: false,
            entitled_resource: [],
            prerequisite_products_required: 'all',
            prerequisite_collections_required: 'all',
            expires_at: {
                start: new Date('Wed Feb 07 2018 00:00:00 GMT-0500 (EST)'),
                end: new Date('Wed Feb 07 2018 00:00:00 GMT-0500 (EST)')
            },
            expire_month: 12,
            expire_year: 2021,
            product: null,
            required_product: null,
            collection: null,
            selectedUsageAmount: 'unlimited_number',
            constraints: [],

            // App state
            product_picker_type: '',
            collection_picker_open: false,
            collections_enabled: false,
            cal: false
        }

        this.handleAddConstraint = this.handleAddConstraint.bind(this);
        this.handleClearConstraint = this.handleClearConstraint.bind(this);
        this.handleOpenResourcePicker = this.handleOpenResourcePicker.bind(this);
        this.handleDiscountCreation = this.handleDiscountCreation.bind(this);
        this.closePicker = this.closePicker.bind(this);
    }

    handleAddConstraint() {
        const defaultConstraint = { type: 'pay', amountType: 'value', payType: 'shipping', prerequisite_customers: [], starts_at: null, ends_at: null };
        this.setState(prevState => ({ constraints: [...prevState.constraints,defaultConstraint] }))
    }

    handleClearConstraint() {
        this.setState({ constraints: [] });
    }

    handleUpdateConstraint(index) {
        return (constraint) => {
            this.setState(prevState => {
                const updatedConstraints = prevState.constraints;
                updatedConstraints[index] = constraint;
                this.setState({ constraints: updatedConstraints });
            });
        };
    }

    handleParseEntitlements() {
        const { entitlement_type, entitled_resource, product_limited_rule, prerequisite_to_entitlement_entitlement_quantity  } = this.state;
        const entitlements = {};
        switch (entitlement_type) {
            case 'variant':
                entitlements.entitled_variant_ids = entitled_resource.map(r => r.id);
                break;
            case 'Product':
                entitlements.entitled_product_ids = entitled_resource.map(p => parseInt(p.id.split("/").slice(-1)));
                break;
            case 'Collection':
                entitlements.entitled_collection_ids = entitled_resource.map(r => r.id);
                break;
            default:
                break;
        }
        if (product_limited_rule === 'limited') {
            entitlements.prerequisite_to_entitlement_entitled_quantity = parseInt(prerequisite_to_entitlement_entitlement_quantity);
        }
        return entitlements;
    }

    handleParsePrerequisites() {
        const { constraints } = this.state;

        const prerequisites = { customer_selection: 'all' };
        for (const constraint of constraints) {
            switch (constraint.type) {
                case 'pay': {
                    if (constraint.payType === 'subtotal') {
                        prerequisites.prerequisite_subtotal_range = constraint.value;
                    } else if (constraint.payType === 'shipping') {
                        prerequisites.prerequisite_shipping_price_range = constraint.value;
                    } else {
                        throw new Error("bad pay-type");
                    }
                    break;
                }
                case 'date': {
                    prerequisites.starts_at = constraint.starts_at;
                    prerequisites.ends_at =  constraint.ends_at;
                    break;
                }
                case 'amount': {
                    prerequisites.prerequisite_to_entitlement_prerequisite_quantity = parseInt(constraint.value);
                    prerequisites.prerequisite_quantity_range = constraint.prerequisite_quantity_range;
                    prerequisites.prerequisite_product_ids = constraint.prerequisite_products.map(product => parseInt(product.id.split("/").slice(-1)));
                    prerequisites.prerequisite_variant_ids = constraint.prerequisite_variant_ids;
                    prerequisites.prerequisite_collection_ids = constraint.prerequisite_collection_ids;
                    break;
                }
                case 'customer': {
                    prerequisites.prerequisite_customer_ids = constraint.prerequisite_customers.map(customer => customer.id);
                    prerequisites.customer_selection = constraint.prerequisite_customers.length === 0 ? 'all' : 'prerequisite';
                    break;
                }
                default: {
                    break;
                }
            }
        }

        return prerequisites;
    }

    handleDiscountCreation() {
        const { createRewardDiscount } = this.props;
        const { usage, title, description, value_type, value, usage_limit, allocation_limit, discount_application, required_points } = this.state;


        const entitlements = this.handleParseEntitlements();
        const prerequisites = this.handleParsePrerequisites();
        const entitled = entitlements.entitled_variant_ids?.length  || entitlements?.entitled_collection_ids?.length  || entitlements?.entitled_variant_ids?.length;

        const payload = {
            application_type: 'point',
            required_points: parseInt(required_points),
            value_type,
            value: parseInt(value),
            target_type: discount_application === 'shipping' ? 'shipping_line' : 'line_item',
            allocation_method: discount_application === 'subtotal' ? 'across' : 'each',
            target_selection : entitled ? 'entitled' : 'all',
            allocation_limit,
            once_per_customer: usage === 'capped' && parseInt(usage_limit) === 1,
            ...(usage === 'capped' && {usage_limit: parseInt(usage_limit)}),
            ...entitlements,
            ...prerequisites,
            title,
            description
        };
        createRewardDiscount(payload)
            .then(() => {
                this.setState({ loading: false })
            })
    }

    clearForm() {
        this.setState({ required_product_id: null, product_id: null, amount: null, required_points: null });
    }

    handleChange(key) {
        return (e) => {
            console.log(e)
            this.setState({
                [key]: e ,
                ...(key === 'prerequisite_products_required' && { entitled_resource: [] })
            })
        };
    }

    handleProductSelection({ selection }) {
        const type = this.state.product_picker_type;
        this.setState({ [type]: selection[0], collection_picker_open: false, product_picker_type: '' })
    }

    handleCollectionSelection({ selection }) {
        this.setState({ collection: selection[0], collection_picker_open: false, product_picker_type: '' })
    }

    handleOpenResourcePicker({ onSelection, type }) {
        this.setState({ pickerOpen: true, onPickerSelection: onSelection, pickerType : type });
    }

    closePicker() {
        this.setState({ pickerOpen: false });
    }

    render() {
        const { entitlement_type, prerequisite_to_entitlement_entitlement_quantity, product_limited_rule, entitled_resource, pickerType, pickerOpen, onPickerSelection, usage, usage_limit, value_type, value, discount_application, type, amount, required_points, expires_at, expire_month, expire_year, collections_enabled, product_picker_type, collection_picker_open, collection, product, required_product, selectedUsageAmount, constraints, title, description } = this.state;

        const submitForm = this.handleDiscountCreation.bind(this);

        const renderChildren = () => (
            <TextField
                label="Minimum Quantity"
                disabled={usage === "unbounded"}
                type={'number'}
                prefix={'#'}
                labelHidden
                onChange={this.handleChange('usage_limit')}
                value={usage_limit}
            />
        );
        const constraintActions = [{content: 'Add', onAction: this.handleAddConstraint }];
        if (constraints.length > 0) {
            constraintActions.unshift({content: 'Clear', destructive: true, onAction: this.handleClearConstraint })
        }

        return (
            <Card
                title={"Create Reward Discount"}
                actions={[{content:"Back"}]}
                sectioned
                footerActionAlignment={"right"}
                >
                <Form preventDefault={true} onSubmit={submitForm} name="Create Discount">
                    <Card.Section title="Reward Requirements">
                        <FormLayout>
                            <TextField
                                placeholder="e.g. '50% OFF'"
                                value={title}
                                inputMode="text"
                                onChange={this.handleChange('title')}
                                label={'Reward Title'}
                                type={'text'}
                            />
                            <TextField
                                value={description}
                                placeholder={"This reward allows you to buy one product and get another for free..."}
                                multiline={2}
                                inputMode="text"
                                onChange={this.handleChange('description')}
                                label={'Reward Description'}
                                type={'text'}
                            />
                            <TextField
                                value={required_points}
                                placeholder={"e.g. 500"}
                                inputMode="numeric"
                                onChange={this.handleChange('required_points')}
                                label={'Required Reward Points'}
                                type={'number'}
                            />
                        </FormLayout>
                    </Card.Section>
                    <Card.Section title="Discount Application">
                        <FormLayout>
                            <RadioButton
                                label="Discount Applies to Shipping"
                                helpText="Customers will be able to use reward to get a discount on shipping."
                                checked={discount_application === 'shipping'}
                                id="shipping"
                                name="shipping"
                                onChange={() => this.handleChange('discount_application')('shipping')}
                            />
                            <RadioButton
                                label="Discount Applies to Subtotal"
                                helpText="Customers will be able to use reward to get a discount on a subtotal of purchase."
                                id="subtotal"
                                name="subtotal"
                                checked={discount_application === 'subtotal'}
                                onChange={() => this.handleChange('discount_application')('subtotal')}
                            />
                            <RadioButton
                                label="Discount Applies to Product(s)"
                                helpText="Customers will be able to use reward to get a discount on a product."
                                id="product"
                                name="accounts"
                                checked={discount_application === 'product'}
                                onChange={() => this.handleChange('discount_application')('product')}
                            />
                        </FormLayout>
                        {discount_application === 'product' && (
                            <>
                            <FormLayout.Group condensed>
                                <TextStyle variation="strong">Discount Can be Applied to</TextStyle>
                            </FormLayout.Group>
                                <FormLayout.Group condensed>
                                    <Select
                                        labelHidden
                                        options={[{label:'Any Product',value:'all'},{label:'The Following Products',value:'Product'},{label:'The Following Collections',value:'Collection'}]}
                                        value={entitlement_type}
                                        onChange={this.handleChange('entitlement_type')}
                                        label="product selection"
                                    />
                                    {entitlement_type !== 'all' &&
                                    <Stack spacing="tight" alignment="center">
                                        {entitled_resource.length === 0 && <Button onClick={() => this.handleOpenResourcePicker({ onSelection: selections => this.handleChange('entitled_resource')(selections.selection), type: entitlement_type })}>{`Add ${entitlement_type}(s)`}</Button>}
                                        {entitled_resource.length > 0 && (<Tag>{entitled_resource[0].title}</Tag>)}
                                        {entitled_resource.length > 1 && (<Tag>+{entitled_resource.length-1}</Tag>)}
                                        {entitled_resource.length > 0 && <Button destructive onClick={() => this.setState({ entitled_resource: [] })}>{`Clear Selected ${entitlement_type}(s)`}</Button>}
                                    </Stack>
                                    }
                                    <Select
                                        label=" "
                                        labelHidden
                                        options={[{label:'On any number of cart items',value:'unlimited'},{label:'On Up to',value:'limited'}]}
                                        value={product_limited_rule}
                                        onChange={this.handleChange('product_limited_rule')}
                                    />
                                    {product_limited_rule === 'limited' &&
                                    <TextField
                                        suffix="cart items."
                                        labelHidden
                                        label={' '}
                                        onChange={this.handleChange('prerequisite_to_entitlement_entitlement_quantity')}
                                        value={prerequisite_to_entitlement_entitlement_quantity}
                                        type="number"
                                    />
                                    }
                                </FormLayout.Group>
                            </>
                        )}
                        <FormLayout.Group>
                            <TextField
                                label="Discount Amount"
                                type="number"
                                value={value}
                                onChange={val => {value_type === 'percentage' ? this.handleChange('value')(val > 100 ? 100 : val) : this.handleChange('value')(val) } }
                                connectedLeft={
                                    <Select
                                        value={value_type}
                                        label="Weight unit"
                                        onChange={this.handleChange('value_type')}
                                        labelHidden
                                        options={[{label:'$',value:'fixed_amount'}, {label:'%',value:'percentage'}]}
                                    />
                                }
                            />
                        </FormLayout.Group>
                    </Card.Section>
                    <Card.Section title="Reward Discount Usage">
                        <FormLayout>
                            <FormLayout.Group>
                                <ChoiceList
                                    title="Number of times to be Used"
                                    choices={[
                                        { label: 'Unlimited Number', value: 'unbounded' },
                                        { label: 'Limited Number', value: 'capped', renderChildren }
                                    ]}
                                    selected={usage}
                                    onChange={e => this.handleChange('usage')(e[0])}
                                />
                            </FormLayout.Group>
                        </FormLayout>
                    </Card.Section>
                    <Card.Section title="Constraints" actions={constraintActions} >
                        {constraints.map((constraint, index) => (
                            <Card.Subsection>
                                <Constraint data={constraint} onUpdate={this.handleUpdateConstraint(index)} openResourcePicker={this.handleOpenResourcePicker} />
                            </Card.Subsection>
                        ))}
                    </Card.Section>
                </Form>
                <ResourcePicker
                    key={pickerType}
                    resourceType={pickerType}
                    showVariants={false}
                    selectMultiple={true}
                    open={pickerOpen}
                    onSelection={e => { onPickerSelection(e); this.closePicker(); }}
                    onCancel={() => this.setState({ pickerOpen: false })}
                />
                <Stack distribution="trailing">
                    <ButtonGroup>
                        <Button plan>Learn More</Button>
                        <Button primary loading={this.state.loading} onClick={() => this.setState({ loading : true }, submitForm())}>Create Discount</Button>
                    </ButtonGroup>
                </Stack>
            </Card>
        )
    }
}

DiscountCreatorModal.propTypes = {
    type: PropTypes.oneOf(['reward_discount', 'sitewide_discount'])
};

export default DiscountCreatorModal;
