import React from 'react';
import PropTypes from 'prop-types';

import { Card, Page, Stack, Subheading, DataTable, EmptyState, Checkbox } from '@shopify/polaris';
import {API_PREFIX} from "../constants";

class RewardAdminInfo extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        sitewide_discounts: [],
        reward_discounts: [],
        reward_customers: [],
      };
    }

    changeActiveState(type, discount) {
        const { refreshDiscounts } = this.props;

        this.setState({ loading: true }, () => {
            const url = `${API_PREFIX}/${type}_discount/`
            fetch(url, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: 'PUT',
                body: JSON.stringify({ id: discount.id, active: !discount.active })
            }).then(response => {
                refreshDiscounts();
            }).finally(() => {
                this.setState({ loading: false })
            })
        })
    }

    render() {
        const {
            sitewide_discounts,
            reward_discounts,
            reward_customers,
            removeDiscount,
            sitewide_rewards
        } = this.props;

        console.log("RewardDiscounts: ", reward_discounts)
        // TODO: SORT BY ACTIVE/INACTIVE REWARDS
        // TODO: FILTER BY ACTIVE/INACTIVE
        return (
            <>
                <Card title="Reward Discounts" sectioned>
                    <>
                        <table style={{ width: '100%' }}>
                            <thead>
                            <tr>
                                <th>Type</th>
                                <th>Amount</th>
                                <th>Required Points</th>
                                <th>Expiry Date</th>
                                <th>Is Active</th>
                            </tr>
                            </thead>
                            <tbody>
                            {(reward_discounts || []).map(discount => (
                                    <tr>
                                        <td>{discount.type}</td>
                                        <td>{discount.amount}</td>
                                        <td>{discount.required_points}</td>
                                        <td>{discount.expires_at}</td>
                                        <td><Checkbox label="Is Active" checked={discount.active} onChange={() => this.changeActiveState('reward', discount )}/></td>
                                    </tr>
                                )
                            )}
                            </tbody>
                        </table>
                        {(!reward_discounts || reward_discounts?.length === 0) &&
                        <EmptyState
                            heading="Added reward discounts will appear here"
                            image="https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg"
                        >
                        </EmptyState>
                        }
                    </>
                </Card>
                <Card sectioned title="Sitewide Rewards">
                    <Stack vertical>
                        <>
                            {(sitewide_discounts || []).map(discount => {
                                <div>{discount.title}</div>
                            })}
                            {(!sitewide_discounts || sitewide_discounts?.length == 0) &&
                            <EmptyState
                                heading="Added sitewide discounts will appear here"
                                image="https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg"
                            >
                            </EmptyState>
                            }
                        </>
                    </Stack>
                </Card>
                <Card sectioned title="Reward Customers">
                    <Stack vertical>
                        {reward_customers && reward_customers.length > 0 &&
                        <DataTable
                            columnContentTypes={[
                                'text',
                                'text',
                                'text',
                                'numeric',
                                'numeric',
                                'numeric',
                            ]}
                            headings={[
                                'First Name',
                                'Last Name',
                                'Email',
                                'Points',
                                'Redeemed Rewards',
                                'Used Rewards',
                            ]}
                            rows={(reward_customers || []).map(customer => {
                                return [customer.first_name, customer.last_name, customer.email, customer.points, customer.redeemed_rewards, customer.used_rewards];
                            })}
                        />
                        }
                        {(!reward_customers || reward_customers?.length == 0) &&
                        <EmptyState
                            heading="Added sitewide discounts will appear here"
                            image="https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg"
                        >
                        </EmptyState>
                        }
                    </Stack>
                </Card>
            </>
        );
    }
}

RewardAdminInfo.propTypes = {
    sitewide_discounts: PropTypes.array,
    reward_discounts: PropTypes.array,
    reward_customers: PropTypes.array,
    removeDiscount: PropTypes.func,
    sitewide_rewards: PropTypes.array
}

RewardAdminInfo.defaultProps = {
    reward_customers: []
};

export default RewardAdminInfo;
