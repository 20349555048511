import {Toast} from "@shopify/polaris";
import {useCallback, useState} from "react";

const RewardToastManager = ({ children }) => {
    const [ toasts, setToasts ] = useState([]);

    const addToast = toast => {
        setToasts(prevState => [...prevState, toast]);
    };
    const removeToast = index => {
        setToasts(prevState => [...prevState.slice(0,index),...prevState.slice(index+1,prevState.length)]);
    };

    return (
        <>
            {toasts.map((toast,index) => !!toast.active && (
                <Toast content={toast.message} onDismiss={() => removeToast(index)} error={toast.error} />
            ))}
            {children({
                addToast
            })}
        </>
    );
};
export default RewardToastManager;
