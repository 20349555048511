import './App.css';
import React, {useEffect} from "react";
import { AppProvider, Stack, Page, Frame, Navigation, TopBar } from '@shopify/polaris';
import translations from '@shopify/polaris/locales/en.json';
import '@shopify/polaris/dist/styles.css';
import {ClientRouter, Provider, RoutePropagator, useAppBridge} from '@shopify/app-bridge-react';
import {Redirect, History} from '@shopify/app-bridge/actions';
import {BrowserRouter, Route, Link as ReactRouterLink, Switch, withRouter} from 'react-router-dom';
import { authenticatedFetch, getSessionToken } from '@shopify/app-bridge-utils';
import enTranslations from '@shopify/polaris/locales/en.json';
import { DiscountCreatorModal, RewardAdminInfo } from './components/'
import { NavigationMarkup, NAV_STATE } from './Navigation';
import  LoyaltyAdmin  from './LoyaltyAdmin';
import AppStateProvider from './AppStateProvider'
import AnalyticsAdmin from "./AnalyticsAdmin";
import MarketingAdmin from "./MarketingAdmin";
import Settings from "./Settings";
import Contact from "./Contact";
import RewardToastManager from "./components/RewardToastManager";
import Routes from "./Routes";
import * as url from "url";

const IS_EXTERNAL_LINK_REGEX = /^(?:[a-z][a-z\d+.-]*:|\/\/)/;

function Link({children, url = '', external, ref, ...rest}) {
    // react-router only supports links to pages it can handle itself. It does not
    // support arbitrary links, so anything that is not a path-based link should
    // use a regular old `a` tag
    if (external || IS_EXTERNAL_LINK_REGEX.test(url)) {
        rest.target = '_blank';
        rest.rel = 'noopener noreferrer';
        return (
            <a href={url} {...rest}>
                {children}
            </a>
        );
    }

    return (
        <ReactRouterLink to={url} {...rest}>
            {children}
        </ReactRouterLink>
    );
}

function InnerApp() {
    const app = useAppBridge();

    // Generate App-Bridge Functions
    const fetchFunction = authenticatedFetch(app);
    const historyFunction = History.create(app);
    const redirectFunction = Redirect.create(app);

    // Determine if we need to change scopes
    // fetchFunction('/api/shopify/')
    //     .then(res => {
    //         if (!res.ok) {
    //             throw new Error("bad request");
    //         }
    //         if (res.headers)
    //             return res.json();
    //     })
    //     .then(data => {
    //         if (data.requires_auth) {
    //             const searchParams = new URL(data.url).searchParams;
    //             const redirect = Redirect.create(app);
    //             let query = [];
    //             searchParams.forEach((val,key) => query.push(key+"="+val));
    //             redirect.dispatch(Redirect.Action.ADMIN_PATH, `/oauth/authorize?${query.join("&")}`);
    //         } else if (data.updated) {
    //             const searchParams = new URL(data.url).searchParams;
    //             const redirect = Redirect.create(app);
    //             let query = [];
    //             searchParams.forEach((val,key) => query.push(key+"="+val));
    //             redirect.dispatch(Redirect.Action.ADMIN_PATH, `/oauth/authorize?${query.join("&")}`);
    //         }
    //     })
    //     .catch(err => {
    //         console.log(err)
    //     });

    return (
        <AppStateProvider fetchFunction={fetchFunction}>
            {props => (
                <RewardToastManager>
                    {({ addToast }) => (
                        <Frame
                            // topBar={topBarMarkup}
                            navigation={<NavigationMarkup redirectFunction={redirectFunction} historyFunction={historyFunction} updateNavigation={props.updateNavigation} />}
                            showMobileNavigation={true}
                            onNavigationDismiss={()=>{}}
                            skipToContentTarget={false}
                        >
                            <Routes addToast={addToast} {...props} />
                        </Frame>
                    )}
                </RewardToastManager>
            )}
        </AppStateProvider>
    );
}


function App() {
    // Otherwise check for other permissions
    let shopOrigin = window.shopOrigin;
    if (!shopOrigin) {
        shopOrigin = window.location?.ancestorOrigins[0]?.replace('https://','');
    }
    if (!shopOrigin) {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        if (urlParams.get('shop')) {
            shopOrigin = urlParams.get('shop');
        }
    }
    if (shopOrigin) {
        window.localStorage.setItem('shopOrigin', shopOrigin);
    } else {
        shopOrigin = window.localStorage.getItem('shopOrigin');
    }
    const config = {
        apiKey: 'd48c414f75f92d93840675f6250ab28b',
        shopOrigin,
        forceRedirect: true,
    };
    const { pathname } = window.location;
    const basename = pathname.endsWith("/") ? pathname.slice(0,-1) : pathname;
    const scopes = "read_orders,write_order_edits,write_orders,read_customers,write_customers,read_products,write_products,read_price_rules,write_price_rules,read_discounts,write_discounts,read_fulfillments,write_fulfillments,read_draft_orders,write_draft_orders,read_themes,write_themes,read_locations,read_marketing_events,read_product_listings,read_checkouts,write_checkouts";

    if (window.top == window.self) {
        const permissionUrl = `https://${config.shopOrigin}/admin/oauth/authorize?client_id=${config.apiKey}&scope=${scopes}&redirect_uri=https://shopify.rewarduckie.com/api/shopify/redirect_uri/`;
        window.location.assign(permissionUrl);
        console.log('nice')
        return
    }

    return (
        <BrowserRouter basename={basename}>
            <AppProvider i18n={translations} linkComponent={Link}>
                <Provider config={config}>
                    <InnerApp />
                </Provider>
            </AppProvider>
        </BrowserRouter>
    );
}

export default App;
